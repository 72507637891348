import React from 'react'

const ValidDate = (props) =>{

    let date = new Date(props.date)
    const day = date.getDate()
    const month = date.getMonth() + 1
    const formatedDate = `${(day < 10 ? '0' : '') + day}.${(month < 10 ? '0' : '') + month}.${date.getFullYear()}`

    return(
        <>
              <span>{formatedDate}</span>
        </>
    )
}
export default ValidDate