import React from 'react'
import { graphql, useStaticQuery, navigate } from 'gatsby'

// styles
import './category.scss'

const CategoryMobile = (props) => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulCategory {
          edges {
            node {
              slug
              type
            }
          }
        }
      }
    `
  )

  const RedirectLink = () => {
    const type = document.getElementById('category').value
    navigate(`/blog/${type}`)
  }
  const slug = props.slug !== undefined ? props.slug : 'nenhum'

  return (
    <>
      <div className='d-block d-lg-none'>
        <select id='category' name='category' className='form-control font-openSans mb-4 ' onChange={() => RedirectLink()}>
          {props.type !== undefined &&
          <option value='' disabled selected>{props.type}</option>
          }
          {
            data.allContentfulCategory.edges.map((post, index) => {
              if (post.node.slug !== slug) {
                return (
                  <option value={post.node.slug} key={index} >
                    {post.node.type}
                  </option>
                )
              }
            })
          }
        </select>
      </div>
    </>
  )
}
export default CategoryMobile
