import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";

// styles
import './category.scss'

const Category = ({ path }) => {
  const data = useStaticQuery(
    graphql`{
  allContentfulBlog(sort: {fields: date, order: DESC}, limit: 5) {
    edges {
      node {
        slug
        title
        card {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
  allContentfulCategory {
    edges {
      node {
        slug
        type
      }
    }
  }
}
`
  )

  const _postagens = data.allContentfulBlog.edges.filter(edge => edge.node.slug !== path)

  return <>
    {
      data.allContentfulCategory.edges.map((post, index) => {
        return (
          <Link className='position-relative category-blog-color mb-3 d-none d-lg-block' key={index} to={`/blog/${post.node.slug}/`}>
            <div className='d-flex align-items-center w-100 category-name-blog col-12' style={{ backgroundColor: '#015592' }}>
              <p className='text-capitalize font-openSans-semibold'>{post.node.type}</p>
            </div>
          </Link>
        )
      })
    }

    <h2 className='mt-lg-5 mb-2'>Últimos Posts</h2>
    {
      _postagens.map((post, index) => {
        return (
          <Link className='d-flex mb-4' key={index} to={`/blog/${post.node.slug}/`}>
            <div className='position-relative img-lastposts'>
              <GatsbyImage
                image={post.node.card.localFile.childImageSharp.gatsbyImageData}
                alt={post.node.slug}
                className='h-100' />
            </div>
            <p className='ml-4 font-openSans-semibold title-lastposts'>{post.node.title}</p>
          </Link>
        );
      })
    }
  </>;
}

export default Category
