import React, { Fragment, useRef, useState } from 'react'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

// components
import Layout from '../../components/Layout/index'
import Category from '../../components/Category/category'
import CardOferta from '../../components/cardOfertas/cardOfertas'
import Instagram from '../../components/Instagram/instagram'
import ValidDate from '../../components/validDate/validDate'
import CategoryMobile from '../../components/Category/categoryMobile'

// styles
import './blog-categorias.scss'

// icons
import setaVoltar from '../../images/icons/post-blog/seta-voltar.svg'

const Cards = (props) => {
  return (
    <div className='row pb-4 d-none d-sm-flex'>
      {
        props.data.map(post => {
          const date = new Date(post.node.date)
          return (
            <div className='col-12 col-sm-6'>
              <div className='sizeimg-post-blog'>
                <GatsbyImage
                  image={post.node.card.localFile.childImageSharp.gatsbyImageData}
                  alt={post.node.slug}
                  className='w-100 h-100' />
              </div>
              <div className='position-absolute d-flex justify-content-center alignt-items-center flex-column text-center text-uppercase circle-blog'>
                <p className='text-capitalize'>{date.getDate()}</p>
                <p className='text-uppercase'>{props.mouths[date.getMonth()]}</p>
              </div>
              <div className='title-blog mt-2 col-12 p-0'>
                <ValidDate date={post.node.date} />
                <h2 className='mt-2'>{post.node.title}</h2>
                <p className='text-blog'>{post.node.subtitle}</p>
                <Link to={`/blog/${post.node.slug}/`} className='btn btn-blog d-flex justify-content-center align-items-center text-center text-uppercase mt-4 mb-4'>
                  Leia Mais
                </Link>
              </div>
            </div>
          );
        })
      }
    </div>
  );
}

const CardMobile = (props) => {
  return <>
    {
      props.data.map(post => {
        const date = new Date(post.node.date)
        return (
          <div className='col-xl-4 col-lg-6 pb-4 d-flex d-sm-none justify-content-between p-0' key={props.index}>
            <div className='col-6 col-sm-12 p-0 sizeimg-post-blogMobile'>
              <GatsbyImage
                image={post.node.card.localFile.childImageSharp.gatsbyImageData}
                alt={post.node.slug}
                className='w-100 h-100' />
              <div className='position-absolute d-flex justify-content-center alignt-items-center flex-column text-center text-uppercase circle-blogMobile'>
                <p className='text-capitalize'>{date.getDate()}</p>
                <p className='text-uppercase'>{props.mouths[date.getMonth()]}</p>
              </div>
            </div>
            <div className='caption-blogMobile col-6 col-sm-12 pl-3 p-0'>
              <p className='font-weight-bold pt-0 pt-sm-3 pb-0'>{post.node.category.type}</p>
              <h2 className='pb-1'>{post.node.title}</h2>
              <Link to={`/blog/${post.node.slug}/`} className='btn btn-blog d-flex justify-content-center align-items-center text-center text-uppercase w-100'>
                Leia Mais
              </Link>
            </div>
          </div>
        )
      })
    }
  </>
}

const BlogCategoria = ({ pageContext, data }) => {
  const breadC = {
    visible: true,
    items: [
      { path: '/', name: 'Institucional' },
      { path: '/blog/', name: 'Blog' }
    ]
  }

  const [currentMarket, setCurrentMarket] = useState(typeof window !== 'undefined' ? JSON.parse(window.localStorage.getItem('currentMarket')) : null)
  const modalRef = useRef()
  const posts = data.posts.edges
  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? '' : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  const mouths = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ]

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <html lang="pt" />
        <title>{`${pageContext.categoryType} | Spani`}</title>
        <meta name="title" content={`${pageContext.categorySlug} | - SPANI ATACADISTA`} />
        <meta name="description" content='Fique por dentro do nosso blog, e veja todas as nossas categorias.' />
        <meta name="keywords" content='Categoria, Blog' />
        <meta name="author" content="Spani" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={`${pageContext.categorySlug} | - SPANI ATACADISTA`} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`https://www.spani.com.br${pageContext.categorySlug}`} />
        <meta
          property="og:image"
          content={`https://www.spani.com.br${data.metaBanner.publicURL}`}
        />
        <meta property="og:description" content='Categorias' />
      </Helmet>
      <Layout breadCrumb={breadC} homeCallback={setCurrentMarket} ref={modalRef}>
        <div className='container d-block d-sm-none'>
          <div className='row mt-4'>
            <div className='col-12'>
              <CategoryMobile type={pageContext.categoryType} slug={pageContext.categorySlug} ></CategoryMobile>
            </div>
          </div>
        </div>
        <div className='bg-gray'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 pb-lg-4 pb-2 pt-lg-4 header-blog'>
                <div className='row pb-4'>
                  <div className='col-10'>
                    <h1 className='font-source-bold m-0'>Blog Do Spani</h1>
                  </div>
                  <div className='col-2 d-flex justify-content-end align-items-center'>
                    <a href="javascript:history.back()" className="d-flex align-items-center rounded px-sm-3 px-2 py-sm-1 py-2">
                      <img src={setaVoltar} alt="seta de voltar" className="m-0 mr-sm-2"/>
                      <span className="text-uppercase d-none d-sm-block">Voltar</span>
                    </a>
                  </div>
                  <div className='col-12'>
                    <p className='d-none d-sm-flex'>Fique por dentro de tudo sobre nós</p>
                    <h3 className='d-flex d-sm-none m-0'>{pageContext.categoryType}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className='row d-none d-lg-flex'>
              <div className='col-12 col-lg-8 '>
                <h2 className='font-baloo-extrabold mt-4'>
                  {pageContext.categoryType}
                </h2>
              </div>
              <div className='col-4 d-none d-lg-block'>
                <h2 className='font-baloo-extrabold mt-4'>Categorias</h2>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-lg-8'>
                <Cards data={posts} pagination={currentPage} mouths={mouths} />
                <CardMobile data={posts} pagination={currentPage} mouths={mouths} />
                <div className='d-flex justify-content-center align-items-center py-3'>
                  <Link
                    to={`/blog/${pageContext.categorySlug}/${isFirst ? '' : `${prevPage}`}`}
                    rel="prev"
                    className={isFirst ? 'disabled-link bg-transparent border-0 px-4' : 'bg-transparent border-0 px-4'}
                  >
                    <i className={!isFirst ? 'arrow-dark arrow-left' : 'arrow-gray arrow-left'}></i>
                  </Link>
                  <div className="row justify-content-center">
                    {Array.from({ length: numPages }, (_, i) => (
                      <div className="col d-flex justify-content-center px-2 p-sm-3" >
                        <span>
                          <Link
                            key={`pagination-number${i + 1}`}
                            to={`/blog/${pageContext.categorySlug}/${i === 0 ? '' : `${i + 1}/`}`}
                            className={i + 1 === currentPage ? 'rounded px-3 py-2 text-white bg-blue border-0' : 'px-3 py-2 border-0 bg-transparent'}
                            style={{ outline: 'none' }}
                          >
                            {i + 1}
                          </Link>
                        </span>
                      </div>

                    ))}
                  </div>
                  <Link
                    to={`/blog/${pageContext.categorySlug}/${isLast ? `${currentPage === 1 ? '' : currentPage}` : `${nextPage}/`}`}
                    rel="next"
                    className={isLast ? 'disabled-link bg-transparent border-0 px-4' : 'bg-transparent border-0 px-4'}
                  >
                    <i className={!isLast ? 'arrow-dark arrow-right' : 'arrow-gray arrow-right'}></i>
                  </Link>
                </div>
                <div className='col-12 pt-4 pb-2 p-0 d-block d-lg-none '>
                  <hr/>
                </div>
              </div>
              <div className='col-12 col-lg-4'>
                <Category path = 'blog-categorias' />
              </div>
            </div>
          </div>
        </div>

        <div className='pt-4'>
          {data.infoCards.edges.length !== 0 && <CardOferta modal={modalRef} current={currentMarket} info={data.infoCards}></CardOferta>}
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-12 mt-4 mb-3'>
              <hr className='d-block d-lg-none'/>
            </div>
          </div>
        </div>
        <Instagram />
      </Layout>
    </Fragment>
  )
}
export default BlogCategoria
export const query = graphql`query ($data: Date!, $skip: Int!, $limit: Int!, $categoryType: String!) {
  posts: allContentfulBlog(
    limit: $limit
    skip: $skip
    filter: {category: {type: {eq: $categoryType}}}
    sort: {fields: date, order: ASC}
  ) {
    edges {
      node {
        slug
        title
        subtitle
        date
        card {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
        category {
          type
        }
      }
    }
  }
  infoCards: allContentfulOfertas(
    filter: {dataInicio: {lte: $data}, dataFim: {gte: $data}}
  ) {
    edges {
      node {
        nome
        dataInicio
        dataFim
        ofertasCards {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
  metaBanner: file(relativePath: {eq: "semBanner.png"}) {
    publicURL
  }
  site {
    siteMetadata {
      siteUrl
    }
  }
}
`
